/* eslint-disable react/display-name */
import { Fragment, useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Nav from "../Nav/Nav";
import styles from "./Confirmation.module.scss";
import { arrayOf } from "../../../utils/array";
import { Link } from "react-router-dom";
import { ConsultantPaths as Paths } from "../../../constants/paths";
import Button, { LIGHT_ALT } from "../../common/Button/Button";
import { JobsterContext } from "../../../context/JobsterContext";

const Confirmation = () => {
  const { isJobsterReady } = useContext(JobsterContext);

  useEffect(() => {
    if (isJobsterReady && window.jobster) {
      try {
        window.jobster.push(function () {
          // eslint-disable-next-line no-undef
          window.jobster.conversion("f5561913-1022-4863-97c3-65baf3385f94", "customer");
        });
      } catch (error) {
        console.log("Error running conversion script:", error);
      }
    }
  }, [isJobsterReady]);

  return (
    <Fragment>
      <Nav showBack={false} />
      <div className="container text-white">
        <div className="row">
          <div className="col-md-6">
            <div className={`${styles.heading} font-weight-700`}>
              <FormattedMessage id="confirmation.heading" />
            </div>
            {arrayOf(2).map((_, i) => (
              // eslint-disable-next-line react/jsx-key
              <div className="my-4 d-flex">
                <div className="ml-2">
                  <FormattedMessage id={`confirmation.body${i + 1}`} />
                </div>
              </div>
            ))}
            <div className="mt-4 d-flex">
              <div className="ml-2">
                <Link className="text-decoration-none" to={Paths.dashboard()}>
                  <Button variant={LIGHT_ALT} className="space-letters">
                    <FormattedMessage id="confirmation.login" />
                  </Button>
                </Link>
              </div>
            </div>

            <div className="mt-4 d-flex">
              <div className="ml-2">
                <FormattedMessage id={"confirmation.body3"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Confirmation;
