import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import useLocalStorage from "react-use-localstorage";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AnalyticsBrowser } from "@segment/analytics-next";
import posthog from "posthog-js";

import createClient from "./apollo";
import { TOKEN } from "./constants/session";
import favicon from "./assets/favicon.svg";
import "tippy.js/dist/tippy.css";
import "./App.scss";
import "react-slidedown/lib/slidedown.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import Router from "./components/Router";
import { JobsterProvider } from "./context/JobsterContext";

const isProduction = process.env.NODE_ENV === "production";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV !== "production" ? "development" : "production",
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
});

if (isProduction) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
  });
}

const App = () => {
  const [token] = useLocalStorage(TOKEN);

  // setup analytics
  useEffect(() => {
    if (!isProduction) {
      return;
    }

    const loadAnalytics = async () => {
      try {
        const [response] = await AnalyticsBrowser.load({
          writeKey: process.env.REACT_APP_SEGMENT_KEY,
        });
        window.analytics = response;
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    loadAnalytics();
  }, []);

  return (
    <BrowserRouter>
      <Helmet defaultTitle="Imagen Insights">
        <link href={favicon} rel="icon" type="image/svg" />
      </Helmet>
      <ApolloProvider client={createClient(token)}>
        <JobsterProvider>
          <RecoilRoot>
            <Router />
            <ToastContainer position="bottom-center" />
          </RecoilRoot>
        </JobsterProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
