import React, { createContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const JobsterContext = createContext();

export const JobsterProvider = ({ children }) => {
  const [isJobsterReady, setIsJobsterReady] = useState(false);

  useEffect(() => {
    const loadJobster = new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://cdn.platform.jobster.com/sdk.js";
      script.async = true;
      script.onload = () => {
        if (!window.jobster) {
          window.jobster = [];
        }

        window.jobster.push(function () {
          window.jobster.init();
          resolve();
        });
      };
      script.onerror = () => reject(new Error("Failed to load Jobster SDK"));
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    });

    loadJobster.then(() => setIsJobsterReady(true)).catch(console.error);
  }, []);

  return (
    <JobsterContext.Provider value={{ isJobsterReady }}>
      {children}
    </JobsterContext.Provider>
  );
}; 

JobsterProvider.propTypes = {
    children: PropTypes.node,
  };
  
  JobsterProvider.defaultProps = {
    children: null,
  };
  